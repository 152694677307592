<template>
  <div>
    <v-dialog
      v-model="viewDialog"
      persistent
      max-width="900px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Purchase Invoice') }}: {{ formData.invoice_no }}
        </v-card-title>

        <v-card-text v-if="viewDialog">
          <v-simple-table
            id="print-area"
            class="print-table"
            dense
          >
            <template #default>
              <thead>
                <invoice-header />

                <tr>
                  <th
                    colspan="100"
                    class="h-25px text-body-2"
                  >
                    <table class="w-100">
                      <tr>
                        <th class="text-start h-25px">
                          {{ $t('Supplier') }}: <span class="font-weight-regular">{{ formData.supplier.name }}</span>
                        </th>
                        <th class="text-center h-25px">
                          {{ $t('Invoice No.') }}: <span class="font-weight-regular">{{ formData.invoice_no }}</span>
                        </th>
                        <th class="text-end h-25px">
                          {{ $t('Date') }}: <span class="font-weight-regular">{{ $_format_date(formData.action_date) }}</span>
                        </th>
                      </tr>
                    </table>
                  </th>
                </tr>

                <tr>
                  <th
                    colspan="100"
                    class="h-25px text-body-2"
                  >
                    <table class="w-100">
                      <tr>
                        <th
                          colspan="2"
                          class="text-start h-25px"
                        >
                          {{ $t('Address') }}: <span class="font-weight-regular">{{ formData.supplier.address }}</span>
                        </th>
                        <th class="text-end h-25px">
                          {{ $t('Phone') }}: <span class="font-weight-regular">{{ formData.supplier.phone }}</span>
                        </th>
                      </tr>
                    </table>
                  </th>
                </tr>

                <tr>
                  <th
                    colspan="100"
                    class="text-start h-25px text-body-2"
                    style="border-bottom: 1px solid #ccc"
                  >
                    {{ $t('Note') }}: <span class="font-weight-regular">{{ formData.note || '' }}</span>
                  </th>
                </tr>

                <tr>
                  <th>#</th>
                  <th>{{ $t('Name') }}</th>
                  <th>{{ $t('Code') }}</th>
                  <th>{{ $t('Packet') }}</th>
                  <th class="text-center">
                    {{ $t('Quantity') }}
                  </th>
                  <th class="text-center">
                    {{ $t('Packet Price') }}
                  </th>
                  <th class="text-center">
                    {{ $t('Purchase Price') }}
                  </th>
                  <th class="text-center">
                    {{ $t('Total Price') }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(detail, index) in formData.details"
                  :key="detail.id"
                >
                  <td class="text-start">
                    {{ index + 1 }}
                  </td>
                  <td class="text-start">
                    {{ detail.item_name }}
                  </td>
                  <td class="text-start">
                    {{ detail.item_code }}
                  </td>
                  <td class="text-start">
                    {{ detail.packet }}
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.quantity) }}
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.packet_price) }}
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.purchase_price) }}
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.total_purchase_price) }}
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="100"
                    class="text-end border-none h-25px pt-3"
                  >
                    <strong>{{ $t('Total Price') }}: </strong> {{ $_format_number(formData.net_price) }}
                  </td>
                </tr>
                <!-- <tr>
                  <td
                    colspan="100"
                    class="text-end border-none h-25px"
                  >
                    <strong>{{ $t('Old Loan') }}: </strong> {{ $_format_number(formData.old_loan) }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="100"
                    class="text-end border-none h-25px"
                  >
                    <strong>{{ $t('Current Loan') }}: </strong> {{ $_format_number(formData.current_loan) }}
                  </td>
                </tr> -->
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <app-form-tip />

          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <!-- <v-btn
            color="primary"
            depressed
            small
            @click="$_print()"
          >
            <v-icon left>
              mdil-printer
            </v-icon>
            <span class="pe-2">
              {{ $t('Print') }}
            </span>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    viewDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
  },
}
</script>
<style>
.h-25px {
  height: 25px !important;
}
.w-100 {
  width: 100% !important;
}
.border-none {
  border: none !important;
}
</style>